import 'dayjs/locale/nl';
import dsTranslations from '@loveholidays/design-system/lib/translations/nl-NL.json';
import dayjs from 'dayjs';

dayjs.locale('nl');

import translations from './main.json';

export const namespaces = {
  '': translations,
  lvhds: dsTranslations,
};
